import { Empty, Typography } from "antd";
import { ReactNode } from "react";
import blankIcon from "~/public/static/blank.png";

const { Text } = Typography;

const EmptyList = ({
  children,
  description,
  hasData = false,
}: {
  children?: ReactNode;
  description?: string;
  hasData?: boolean;
}) => {
  if (hasData) return <>{children}</>;

  const emptyDescription = description || "暂无数据";

  return (
    <div className="my-50">
      <Empty description={<Text type="secondary">{emptyDescription}</Text>} image={blankIcon.src} />
    </div>
  );
};

export default EmptyList;
