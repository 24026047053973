import { useState } from "react";
import { useRouter } from "next/router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { fetchApkVersionsData, fetchApkVersion } from "../../redux/actions/apk";
import {
  AndroidFilled,
  DoubleRightOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Button, Tag } from "antd";

import dayjs from "dayjs";
import EmptyList from "components/app/EmptyList";
import { isWechatBrowser } from "../../utils/wechat";

const Divider = ({ children }: any) => {
  return (
    <div className="divider">
      <div>{children}</div>
      <div className="line"></div>
    </div>
  );
};

const Page = ({ apkVersion, apkVersions: apks }: any) => {
  const router = useRouter();
  const [apkVersions, setApkVersions] = useState(apks.apk_versions);
  const [page, setPage] = useState(1);
  const [showMore, setShowMore] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);

  const loadMoreApks = async () => {
    setPage(page + 1);
    const response = await fetchApkVersionsData({ page: page + 1 });
    if (response.apk_versions.length === 0) {
      setShowMore(false);
    }
    setApkVersions([...apkVersions, ...response.apk_versions]);
  };

  const truncateHTML = (html: any, limit: any) => {
    if (typeof window === "undefined") return "";
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    let truncated = tempDiv.textContent || tempDiv.innerText || "";
    return truncated.length > limit
      ? truncated.substring(0, limit) + "..."
      : truncated;
  };

  const handleClick = () => {
    if (isWechatBrowser()) {
      setShowOverlay(true);
    } else {
      window.location.href = apkVersion.file;
    }
  };

  return apks.apk_versions.length > 0 ? (
    <div>
      {isWechatBrowser() && (
        <div className="apk-download-top">
          点击右上角按钮，然后在弹出的菜单中，点击在浏览器中打开，即可安装
          <img className="arrow" src="/static/arrow.png" />
        </div>
      )}
      <div className={showOverlay ? "apk-overlay show-overlay" : "apk-overlay"}>
        <div className="content">
          <div className="one">
            <div>
              <div className="num">1</div>请点击右上角
            </div>
            <div className="more-wrapper">
              <MoreOutlined className="more" />
            </div>
          </div>
          <div className="two">
            <div className="num">2</div>选择
            <span className="theme-color">「在默认浏览器中打开」</span>
          </div>
        </div>
        <img src="/static/apk-overlay.jpg" />
      </div>
      <div className="apk-top"></div>
      <div className="text-center apk">
        <img width={100} src={apkVersion.icon} />
        <h1 className="mt-30">{apkVersion.app_name}</h1>
        <p className="mt-30">
          <AndroidFilled className="mr-10" />
          适用于 Android 设备
        </p>
        <img src="/static/apk.png" width={130} className="mt-20" />
        <Button
          className="mt-30 btn-block"
          style={{ width: 200, margin: "40px auto" }}
          shape="round"
          type="primary"
          onClick={handleClick}
        >
          安装
        </Button>
        {apkVersion.id !== apkVersions[0].id && (
          <div onClick={() => router.push("/app")}>
            当前版本不是最新，
            <span className="theme-color cursor-pointer">点击下载最新版本</span>
          </div>
        )}

        <Divider>关于电鸭</Divider>
        <div className="text-left latest-version">
          <div className="latest-version-item">
            <div>大小：</div>
            <div>{apkVersion.file_size}MB</div>
          </div>
          <div className="latest-version-item">
            <div>更新时间：</div>
            <div>{dayjs(apkVersion.release_date).format("YYYY-MM-DD")}</div>
          </div>
          <div className="latest-version-item">
            <div>版本：</div>
            <div>{apkVersion.version}</div>
          </div>
          <div className="latest-version-item">
            <div>Build：</div>
            <div>{apkVersion.build_number}</div>
          </div>
        </div>
        <Divider>更新说明</Divider>
        <div className="text-left release-note">
          <div
            dangerouslySetInnerHTML={{ __html: apkVersion.release_note }}
          ></div>
        </div>
        <Divider>其他版本</Divider>
        <div className="apk-version-list">
          {apkVersions &&
            apkVersions.map((item: any) => (
              <div
                className="apk-version-item"
                onClick={() => router.push(`/app/${item.id}`)}
              >
                <div className="version">
                  {item.version + "(build" + item.build_number + ")"}
                  {item.id === apkVersions[0].id && (
                    <Tag color="#f50" className="new-tag">
                      NEW
                    </Tag>
                  )}
                </div>
                <div className="release-note-short">
                  {truncateHTML(item.release_note, 20)}
                </div>
                <div>{dayjs(item.release_date).format("YYYY-MM-DD")}</div>
              </div>
            ))}
          {showMore && (
            <div className="apk-version-list-more" onClick={loadMoreApks}>
              <DoubleRightOutlined className="apk-version-list-more-icon" />
              查看更多
            </div>
          )}
        </div>
        <Divider>电鸭的介绍</Divider>
        <div className="text-justify text-left font-16 description">
          <div>
            <p>电鸭-灵活用工、远程外包服务的移动社区</p>
            <div>
              电鸭是一款专注于灵活用工和远程外包服务的移动社区。在这里，您可以找到大量用户分享的求职心得，并获得丰富的招聘信息。尤其适合互联网工作者，您可以在线进行远程面试和工作，体验不同的工作方式所带来的便捷。
            </div>
            <div>
              此外，各大企业还在电鸭上发布招聘信息。如果您对某个职位感兴趣，您甚至可以在应用上进行在线面试，非常方便快捷。
            </div>
          </div>
          <div>
            <div>主要特点：</div>
            <div>- 丰富的招聘信息 </div>
            <div>- 灵活用工和远程外包服务</div>
            <div>- 在线远程面试和工作</div>
            <div>- 便捷的工作方式 </div>
            <div>- 大企业发布的招聘信息</div>
            <div>- 快捷的在线面试</div>
          </div>
          <div>
            <div>下载电鸭，开启您的灵活用工和远程工作之旅！</div>
            <div> #电鸭 #招聘信息 #远程工作 #在线面试 #灵活用工</div>
          </div>
        </div>
        <div className="text-justify font-16 description">
          <div>
            <p>
              Duckling - Mobile Community for Flexible Employment and Remote
              Outsourcing
            </p>
            <div>
              Duckling is a mobile community that focuses on flexible employment
              and remote outsourcing. Here, you can find a wealth of user-shared
              job-seeking experiences and abundant recruitment information. It
              is especially suitable for internet professionals, as you can
              conduct remote interviews and work online, experiencing the
              convenience brought by different working methods.
            </div>
            <div>
              Furthermore, major companies also post job openings on Duckling.
              If you find a position that interests you, you can even have an
              online interview through the app, making the process incredibly
              convenient and efficient.
            </div>
          </div>
          <div>
            <div>Key Features:</div>
            <div>- Abundant job opportunities </div>
            <div>- Flexible employment and remote outsourcing services</div>
            <div>- Online remote interviews and work</div>
            <div>- Convenient working methods </div>
            <div>- Job openings from major companies</div>
            <div>- Quick online interviews</div>
          </div>
          <div>
            <div>
              Download Duckling now and embark on your journey of flexible
              employment and remote work!
            </div>
            <div>
              #Duckling #JobOpportunities #RemoteWork #OnlineInterviews
              #FlexibleEmployment
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <EmptyList />
  );
};
const mapStateToProps = (state: any) => ({
  token: state.user.token,
  followedCities: state.app.followedCities,
});
const mapDispatchToProps = (dispatch: any) => bindActionCreators({}, dispatch);

Page.requireUser = () => false;

Page.getInitialProps = async (ctx: any) => {
  const { id } = ctx.query;
  let apkVersions,
    apkVersion = {};
  apkVersions = await fetchApkVersionsData({ page: 1 });
  if (id) {
    apkVersion = await fetchApkVersion(id);
  } else {
    apkVersion = apkVersions.apk_versions[0];
  }

  return {
    apkVersions,
    apkVersion,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
